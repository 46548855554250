class CounterArrow {
    constructor(el) {
        this.el = el;
        this.valueEl = el.querySelector('.counter-value > span');
        this.init();
    }

    init() {
        this.finalNumber = parseInt(this.valueEl.innerHTML, 10);
        this.el.dataset.counterArrow = `${ this.finalNumber }`;
        this.valueEl.innerHTML = '0';

        this.delay = 1300 / this.finalNumber;
        this.currentNumber = 0;
    }

    run() {
        setTimeout(() => {
            const increment = setInterval(() => {
                this.valueEl.innerHTML = this.currentNumber;
                if (this.currentNumber < this.finalNumber) {
                    this.currentNumber += 1;
                } else {
                    clearInterval(increment)
                }
            }, this.delay)
        }, 250)
    }
}

export default CounterArrow
