class Navigation {
    constructor(data) {
        this.data = data;
        this.applicationWrapper = $('#tn_app-wrapper');
        this.navigation = $('#navigation-main');
        this.navigationSidebar = $('#navigation-sidebar');
        this.navigationSidebarMobile = $('#navigation-sidebar-mobile');
        this.mobileOpener = $('#navigation-sidebar-mobile-opener');
        this.dropdownContainer = $('#tn_sidebar-dropdown-container');
        this.currentCategory = this.applicationWrapper.data('category');
        const url = window.location.pathname;
        this.currentURL = url.substring(url.lastIndexOf('/') + 1);
        this.init()
    }

    init() {
        if(this.navigation.length) {
            this.generateNavigationMain();
        }
    }

    generateNavigationMain() {
        this.navigation.text('');
        this.data.forEach((nav) => {
            $(`<a class="tn_menu-button-${ nav.icon } ${ this.currentCategory === nav.category ? 'tn_menu-button-selected' : '' }" href="${ nav.url }">${ nav.title }<span class="tn_button-arrow tn_arrow-right"></span></a>`)
                .appendTo(this.navigation);

            if(this.currentCategory === nav.category && this.navigationSidebar.length && typeof nav.articles !== 'undefined') {
                this.generateNavigationSide(nav.articles);
            }

            if(this.currentCategory === nav.category && this.navigationSidebarMobile.length && typeof nav.articles !== 'undefined') {
                this.generateNavigationSideMobile(nav.articles);
            }
        })
    }

    generateNavigationSide(articles) {
        this.navigationSidebar.text('');
        articles.forEach((nav) => {
            $(`<a class="tn_sidebar-button-${ nav.icon } ${ this.currentURL === nav.url ? 'tn_sidebar-button-selected' : '' }" href="${ nav.url }">${ nav.title }</a>`)
                .appendTo(this.navigationSidebar)
        })
    }

    generateNavigationSideMobile(articles) {
        this.navigationSidebarMobile.text('');
        articles.forEach((nav) => {
            $(`<a class="tn_sidebar-button-${ nav.icon }" href="${ this.currentURL === nav.url ? 'javascript:void(0);' : nav.url }">${ nav.title }</a>`)
                .appendTo(this.navigationSidebarMobile)
                .on('click', this.mobileMenuOff.bind(this));

            if(this.mobileOpener.length && this.currentURL === nav.url) {
                this.mobileOpener.text('');
                $(`<a class="tn_sidebar-button-${ nav.icon }" href="javascript:void(0);">${ nav.title }<span class="tn_button-arrow tn_arrow-down"></span></a>`)
                    .appendTo(this.mobileOpener)
                    .on('click', this.mobileMenuOn.bind(this));
            }
        })
    }

    mobileMenuOn() {
        this.dropdownContainer.fadeIn(100);
    }

    mobileMenuOff() {
        this.dropdownContainer.fadeOut(100);
    }
}

export default Navigation
