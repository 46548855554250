import 'donutty/dist/donutty'

class CounterCircular {
    constructor(el) {
        this.$el = $(el);
        this.finalValue = el.dataset.counterCircular || 100;
        this.currentNumber = 0;
        this.delay = 1500 / this.finalValue;
        this.colorTheme = el.dataset.counterCircularTheme || 'blue';
        const options = {
            min: 0,
            max: 100,
            value: 0,
            color: '#00BCEB',
            bg: '#B1EAF8',
            radius: 60,
            thickness: 14,
            padding: 0,
            transition: 'all 2.2s cubic-bezier(0.57, 0.13, 0.18, 0.98)'
        };

        if(this.colorTheme === 'green') {
            options.color = '#6ABF4B';
            options.bg = '#D2EBC7'
        }

        this.donut = new Donutty( el, options );

        this.valueEl = $(`<div class="donut-text">0%</div>`)
            .appendTo(this.$el);
    }

    run() {
        this.donut.set('value', this.finalValue - 5)

        setTimeout(() => {
            const increment = setInterval(() => {
                this.valueEl.text(`${ this.currentNumber }%`);
                if (this.currentNumber < this.finalValue) {
                    this.currentNumber += 1;
                } else {
                    clearInterval(increment)
                }
            }, this.delay)
        }, 250)
    }
}

export default CounterCircular
