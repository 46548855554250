import anime from "animejs";

class CounterX {
    constructor(el) {
        this.el = el;
        this.valueEl = el.querySelector('.counter-value > span');
        this.init();
    }

    init() {
        this.finalNumber = parseInt(this.valueEl.innerHTML, 10);
        this.el.dataset.counterArrow = `${ this.finalNumber }`;
        this.valueEl.innerHTML = '0';
        this.currentNumber = 0;
    }

    run() {
        setTimeout(() => {
            anime({
                targets: this.valueEl,
                textContent: [0, this.finalNumber],
                round: 1,
                easing: 'linear',
                duration: 1000
            });
        }, 250)
    }
}

export default CounterX
