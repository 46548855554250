import { getDataJSON } from "./Helpers";
import Navigation from "./Navigation";
import AnimatedScrollInteraction from "./AnimatedScrollInteraction";
import Swiper from "swiper/bundle";

class Application {
    constructor() {
        this.data = null;
        this.applicationWrapper = $('#tn_app-wrapper');

        this.currentCategory = this.applicationWrapper.data('category');
        this.currentURL = window.location.pathname;

        this.sliderEl = $('#landing-slider');
        this.ctaEl = $('#cta-content');

        this.loadJSON().then(() => {
            this.init();
        });
    }

    loadJSON() {
        const promise = getDataJSON('data.json');

        promise.then((content) => {
            this.data = content;
        });

        return promise;
    }

    init() {
        if(typeof this.data.navigation !== 'undefined') {
            new Navigation(this.data.navigation);
        }

        new AnimatedScrollInteraction();

        this.initSlider();
        this.initCTA();
    }

    initCTA() {
        if(this.ctaEl.length && typeof this.data.cta !== 'undefined') {
            if(this.data.cta.title) {
                $(`<h2>${ this.data.cta.title }</h2>`)
                    .appendTo(this.ctaEl);
            }

            const buttons = $('<ul class="tn_button-array"/>')
                .appendTo(this.ctaEl);

            if(this.data.cta.primary) {
                $(`<li><a class="tn_button-capsule tn_capsule-alternate" href="${ this.data.cta.primary.url }" target="_blank">${ this.data.cta.primary.title }</a></li>`)
                    .appendTo(buttons);
            }
            if(this.data.cta.secondary) {
                $(`<li><a class="tn_button-capsule tn_capsule-secondary" href="${ this.data.cta.secondary.url }" target="_blank">${ this.data.cta.secondary.title }</a></li>`)
                    .appendTo(buttons);
            }
        }
    }

    initSlider() {
        if(this.sliderEl.length && typeof this.data.slider !== 'undefined') {
            this.data.slider.forEach(( item ) => {
                const link = $(`<a class="swiper-slide" href="${ item.url }"><div class="tn_news-graphic"><img src="${item.image}" alt="Graphic" /></div></a>`);
                const content = $('<div class="tn_news-content"></div>')
                    .appendTo(link)
                if(typeof item.date !== 'undefined') {
                    $(`<div class="tn_news-date">${ item.date }</div>`)
                        .appendTo(content);
                }
                $(`<div class="tn_news-headline">${ item.title }</div><div class="tn_news-summary">${ item.subtitle }</div>`)
                    .appendTo(content);

                link.appendTo(this.sliderEl);
            });

            const swiper = new Swiper('#main-slider', {
                loop: true,
                speed: 600,
                spaceBetween: 30,
                pagination: {
                    el: '.tn_carousel-pagination',
                },
                navigation: {
                    nextEl: '.tn_carousel-forward',
                    prevEl: '.tn_carousel-back',
                },
                autoplay: {
                    delay: 5000,
                },
            });
        }
    }

}

export default Application
