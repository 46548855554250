import Highcharts from 'highcharts';

class AnimatedCharts {
    constructor(el) {
        this.el = el;
        this.chartName = el.dataset.appearChart;

        if(this.chartName) {
            this.data = this.loadChart();
            if (typeof this.data.series !== 'undefined') {
                this.data.series.forEach((elem) => {
                    elem.animation = {
                        defer: 300,
                        duration: 1500
                    };
                });
            }
            Highcharts.chart(this.el, this.data);
        }
    }

    loadChart() {
        return this[`load_chart_${this.chartName}`]();
    }

    load_chart_one() {
        var chart = {
            type: 'column',
//                    styledMode: true,
            style: {
                fontFamily: 'CiscoSans'
            },
            height: 360,
        };
        var title = {
            text: '',
            style: {
                color: '#4d4c4c',
                fontWeight: 'bold',
            }
        };
        var xAxis = {
            title: {
                text: 'Baseline',
                align: 'low',
                style: {
                    color: '#4d4c4c',
                    fontSize: '14px',
                }
            },
            categories: ['Jan-20', 'Feb-20', 'Mar-20', 'Apr-20', 'May-20', 'Jun-20', 'Jul-20', 'Aug-20', 'Sep-20', 'Oct-20', 'Nov-20', 'Dec-20'],
            labels: {
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
        };
        var yAxis = {
            title: {
                text: 'Percentage growth in outages relative to January',
                style: {
                    color: '#4d4c4c',
                    fontSize: '16px',
                }
            },
            labels: {
                format: '{value}%',
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
            max: 70,
            min: -10,
            tickInterval: 10
        };
        var tooltip = {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}%</b><br/>'
        };
        var credits = {
            enabled: false
        };
        var series = [
            {
                showInLegend: false,
                name: 'ISP',
                color: '#00bceb',
                data: [0, -5.22022838499184, 52.3654159869494, 38.1729200652529, 26.9168026101142, 25.1223491027732, 28.5481239804241, 58.2381729200653, 8.64600326264273, 28.3849918433932, 25.9380097879282, 0.978792822185981]
            }
        ];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }

        var json = {};
        json.chart = chart;
        json.title = title;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.series = series;
        json.tooltip = tooltip;
        return json;
    }

    load_chart_one_two() {
        var chart = {
            type: 'column',
//                    styledMode: true,
            style: {
                fontFamily: 'CiscoSans'
            },
            height: 360
        };
        var title = {
            text: '',
            style: {
                color: '#4d4c4c',
                fontWeight: 'bold',
            }
        };
        var xAxis = {
            title: {
                text: 'Baseline',
                align: 'low',
                style: {
                    color: '#4d4c4c',
                    fontSize: '14px',
                }
            },
            categories: ['Jan-20', 'Feb-20', 'Mar-20', 'Apr-20', 'May-20', 'Jun-20', 'Jul-20', 'Aug-20', 'Sep-20', 'Oct-20', 'Nov-20', 'Dec-20'],
            labels: {
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
        };
        var yAxis = {
            title: {
                text: 'Percentage growth in outages relative to January',
                style: {
                    color: '#4d4c4c',
                    fontSize: '16px',
                }
            },
            labels: {
                format: '{value}%',
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
            max: 80,
            min: -40,
            tickInterval: 20
        };
        var tooltip = {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}%</b><br/>'
        };
        var credits = {
            enabled: false
        };
        var series = [
            {
                showInLegend: false,
                name: 'CSP',
                color: '#6abf4b',
                data: [0, -5, 36.6666666666667, 25, 18.3333333333333, 41.6666666666667, 65, 73.3333333333333, 5, 70, -20, -31.6666666666667]
            }
        ];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }
        var json = {};
        json.chart = chart;
        json.title = title;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.series = series;
        json.tooltip = tooltip;
        return json;
        // Highcharts.chart(this.el, json);
    }

    load_chart_two() {
        var chart = {
            type: 'line',
//                    styledMode: true,
            style: {
                fontFamily: 'CiscoSans'
            },
            height: 360,
//        backgroundColor: '#f5f5f5'
        };
        var title = {
            text: '',
            style: {
                color: '#4d4c4c',
                fontWeight: 'bold',
            }
        };
        var xAxis = {
            title: {
                text: 'Baseline',
                align: 'low',
                style: {
                    color: '#4d4c4c',
                    fontSize: '14px',
                }
            },
            categories: ['Jan-20', 'Feb-20', 'Mar-20', 'Apr-20', 'May-20', 'Jun-20', 'Jul-20', 'Aug-20', 'Sep-20', 'Oct-20', 'Nov-20', 'Dec-20'],
            labels: {
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
        };
        var yAxis = {
            title: {
                text: 'Percentage change over baseline',
                style:{
                    color: "#4d4c4c",
                    fontSize: "16px"
                }
            },

            labels: {
                format: '{value}%',
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
            max: 400,
            min: 0,
            tickInterval: 100
        };
        var tooltip = {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}%</b>',
        };
        var credits = {
            enabled: false
        };
        var series = [
            {
                name: 'Avg. Anomalies/App',
                color: '#00bceb',
                data: [0, 0, 24.1434866450853, 15.2437315493604, 23.4146719674273, 71.6470894387716, 197.424893707947, 268.370900101106, 248.561657808109, 235.174895940179, 226.103547897335, 184.794396140132]
            },
            {
                name: ' IT Interaction',
                color: '#6abf4b',
                data: [0, 24.9767008387698, 27.8657968313141, 66.4492078285182, 34.4827586206897, 222.367194780988, 278.471575023299, 274.464119291705, 349.301025163094, 389.468779123951, 356.756756756757, 270.643056849953]
            }
        ];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }

        var json = {};
        json.chart = chart;
        json.title = title;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.series = series;
        json.tooltip = tooltip;
        return json;
    }

    load_chart_three() {
        var chart = {
            type: 'line',
            style: {
                fontFamily: 'CiscoSans',
            },
        };
        var title = {
            text: '',
            style: {
                color: '#4d4c4c',
                fontWeight: 'bold',
            }
        };
        var xAxis = {
            categories: ['Jan-20', 'Feb-20', 'Mar-20', 'Apr-20', 'May-20', 'Jun-20', 'Jul-20', 'Aug-20', 'Sep-20', 'Oct-20', 'Nov-20', 'Dec-20'],
            labels: {
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
        };
        var yAxis = {
            title: {
                text: 'Page Load Time (ms)',
                style: {
                    color: '#4d4c4c',
                    fontSize: '16px',
                }
            },
            labels: {
                format: '{value}',
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
            max: 3500,
            min: 0,
            tickInterval: 500
        };
        var tooltip = {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>'
        };
        var credits = {
            enabled: false
        };
        var series = [
            {
                name: 'eCommerce App',
                color: '#00bceb',
                data: [2353.71174359846, 2398.39071591591, 2032.33648841453, 3142.96180379317, 3276.77564391763, 3047.72814049973, 1962.09799659029, 1963.33239252577, 1776.48268721338, 1499.93411249771, 1505.73531858315, 1499.45340719758]
            },
            {
                name: 'Enter. Collaboration App',
                color: '#6abf4b',
                data: [1527.19918768982, 1494.83390860736, 1520.49054005412, 1329.44173451853, 1394.63798609917, 1349.31886819703, 1379.30702820431, 1516.53039216774, 1269.61342621494, 889.475211766379, 973.75142046083, 935.959495647314]
            }
        ];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }

        var json = {};
        json.chart = chart;
        json.title = title;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.series = series;
        json.tooltip = tooltip;
        return json;
    }

    load_chart_four() {
        var chart = {
            type: 'line',
//                    styledMode: true,
            style: {
                fontFamily: 'CiscoSans'
            },
            height: 400,
        };
        var title = {
            text: '',
            style: {
                color: '#4d4c4c',
                fontWeight: 'bold',
            }
        };
        var subtitle = {
            text: '',
            style: {
                "color": "black"
            }
        };
        var xAxis = {
            categories: ['Jan-20', 'Feb-20', 'Mar-20', 'Apr-20', 'May-20', 'Jun-20', 'Jul-20', 'Aug-20', 'Sep-20', 'Oct-20', 'Nov-20', 'Dec-20'],
            labels: {
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            }
        };
        var yAxis = {
            title: {
                text: 'Monthly teleworking traffic as a % of total routing traffic',
                style: {
                    color: '#4d4c4c',
                    fontSize: '16px',
                }
            },
            labels: {
                format: '{value}%',
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            }
        };
        var tooltip = {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}%</b><br/>'
        };
        var credits = {
            enabled: false
        };
        var series = [
            {
                name: 'Education',
                color: '#00bceb',
                data: [1.29904693530817, 1.36740877722094, 2.19514140510954, 3.95239688478323, 4.4578610832023, 3.13980457268713, 2.86745288504149, 3.47597508309372, 3.0276395354176, 2.71847585061968, 3.00823817428634, 2.79789918576934]
            },
            {
                name: 'Financial Services',
                color: '#6abf4b',
                data: [10.9983149663512, 10.3418237082623, 13.7127674505659, 14.9825884793741, 15.2309796122252, 13.1142954149757, 12.2873389338749, 12.8830507985495, 13.7091467498187, 13.1173597792541, 14.6647876495622, 14.8416866425289]
            },
            {
                name: 'Government',
                color: '#a7a9ac',
                data: [2.29853551020677, 2.30779873016479, 3.25815990615178, 3.38405568392156, 2.78854195113555, 2.62432880767549, 3.72768285719244, 4.24334388010391, 4.15475444284582, 4.10248995213232, 4.43630196055145, 5.64828531854947]
            },
            {
                name: 'Health Care',
                color: '#fbab18',
                data: [8.07213555830604, 8.23590186045679, 9.70015150405276, 13.5767164239109, 12.6848746156869, 12.1892827023063, 12.9006786854425, 13.2218635553108, 12.8816693427432, 14.7431253953203, 15.2517831772048, 15.3792314295304]
            },
            {
                name: 'Non-Profit & Membership Orgs',
                color: '#e2231a',
                data: [13.1376373394901, 13.7789033098156, 19.4588000491936, 24.6764381494043, 19.526879235591, 24.2027439045414, 23.237511267833, 17.8477338375429, 17.4422949816739, 17.2124886648054, 22.3367455750361, 23.3983126370614]
            },
            {
                name: 'Professional Services',
                color: '#80ddf4',
                data: [17.5828818732264, 18.0662452189621, 20.2514539222013, 22.0153144222673, 21.3468190467167, 19.9601127354848, 19.6912680433926, 19.5024228950051, 18.673510382737, 19.1514843886671, 22.0882813391192, 23.6185702080485]
            },
            {
                name: 'Total',
                color: '#0d274d',
                lineWidth: 5,
                data: [13.1112075664811, 12.9971510858024, 13.1112927264253, 14.0064088663561, 13.9330756373554, 14.0196572305968, 15.055615035055, 16.135108533423, 16.1566754879021, 15.5412498908935, 15.5308504484853, 15.5806273177093]
            }
        ];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }

        var json = {};
        json.chart = chart;
        json.title = title;
        json.subtitle = subtitle;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.series = series;

        json.tooltip = tooltip;
        // $('#chart_04').highcharts(json);
        return json;
    }

    load_chart_five() {
        var chart = {
            type: 'line',
//                    styledMode: true,
            style: {
                fontFamily: 'CiscoSans'
            },
        };
        var title = {
            text: '',
            style: {
                color: '#4d4c4c',
                fontWeight: 'bold'
            }
        };
        var subtitle = {

        };
        var xAxis = {
            title: {
                text: 'Baseline',
                align: 'low',
                style: {
                    color: '#4d4c4c',
                    fontSize: '14px',
                }
            },
            categories: ['Jan-20', 'Feb-20', 'Mar-20', 'Apr-20', 'May-20', 'Jun-20', 'Jul-20', 'Aug-20', 'Sep-20', 'Oct-20', 'Nov-20', 'Dec-20'],
            labels: {
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
        };
        var yAxis = {
            title: {
                text: 'Percent change since January',
                style: {
                    color: '#4d4c4c',
                    fontSize: '16px',
                }
            },
            labels: {
                format: '{value}%',
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
            max: 70,
            min: 0,
            tickInterval: 10
        };
        var tooltip = {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}%</b><br/>'
        };
        var credits = {
            enabled: false
        };
        var series = [
            {
                showInLegend: false,
                name: '',
                color: '#00bceb',
                data: [0, 3.05159742176682, 42.1812312773251, 60.6520824058239, 59.1251815338395, 61.2749142798143, 64.1402732900642, 56.230021878741, 54.9268456118881, 62.1007643944742, 54.2604691137113, 51.7732306990153]
            }
        ];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }

        var json = {};
        json.chart = chart;
        json.title = title;
        json.subtitle = subtitle;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.series = series;
        json.tooltip = tooltip;
        return json;
        // $('#chart_05').highcharts(json);
    }

    load_chart_seven() {
        var chart = {
            type: 'line',
            style: {
                fontFamily: 'CiscoSans',
            },
        };
        var title = {
            text: '',
            style: {
                color: '#4d4c4c',
                fontWeight: 'bold'
            }
        };
        var subtitle = {
            text: '',
            style: {
                "color": "#4d4c4c",
                fontWeight: 'bold',
            },
            verticalAlign: "top",
            floating: false,
            color: "#4d4c4c"
        };
        var xAxis = {
            title: {
                text: 'Baseline',
                align: 'low',
                style: {
                    color: '#1E4471',
                    fontSize: '14px',
                }
            },
            categories: ['Jan-20', 'Feb-20', 'Mar-20', 'Apr-20', 'May-20', 'Jun-20', 'Jul-20', 'Aug-20', 'Sep-20', 'Oct-20', 'Nov-20', 'Dec-20'],
            labels: {
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            }
        };
        var yAxis = {
            title: {
                text: '2020 PC video minutes, as a percentage of January baseline (normalized)',
                style: {
                    color: '#4d4c4c',
                    fontSize: '16px',
                }
            },
            labels: {
                format: '{value}%',
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
            max: 45,
            min: 0,
            tickInterval: 5
        };
        var tooltip = {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}%</b><br/>'
        };
        var credits = {
            enabled: false
        };
        var series = [
            {
                showInLegend: false,
                name: '',
                color: '#00BCEB',
                data: [0, 6.0475592142998, 16.2374263297218, 40.2862058412024, 27.7007623103015, 19.5383964577205, 22.9131145172749, 24.6721008341166, 28.4994289459645, 34.9001051716439, 25.671268475816]
            }
        ];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }

        var json = {};
        json.chart = chart;
        json.title = title;
        json.subtitle = subtitle;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.series = series;
        json.tooltip = tooltip;
        // $('#chart_07').highcharts(json);
        return json;
    }

    load_chart_eight() { // application 1
        var chart = {
            type: 'column',
//                    styledMode: true,
            style: {
                fontFamily: 'CiscoSans',
                fontSize: '14px'
            },
            label:{
                style: {
                    fontSize: '14px'
                }
            },
            height: 360,
        };
        var title = {
            text: '',
            style: {
                color: '#1E4471',
                fontWeight: 'bold'
            }
        };
        var subtitle = {
            text: '',
            style: {
                "color": "#1E4471",
                fontWeight: 'bold',
            },
            verticalAlign: "top",
            floating: false,
            color: "#1E4471"
        };
        var xAxis = {
            categories: ['Sep-20<br /><span style="color: #1e4471">Baseline</span>', 'Oct-20', 'Jan-21'],
            opposite: false,
            labels: {
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
        };
        var yAxis = {
            title: {
                text: 'Percentage change over baseline',
                style:{
                    color: "#4d4c4c",
                    fontSize: "16px"
                }
            },
            labels: {
                format: '{value}%',
                style:{
                    color: "#4d4c4c",
                    fontSize: "16px"
                }
            },
            max: 50,
            min: 0,
            tickInterval: 10,
            visible: true
        };
        var tooltip = {
            pointFormat: '{series.name}: <b>{point.y}</b>',
            valueSuffix: '%'
        };
        var credits = {
            enabled: false
        };
        var legend = {
            title: {
                text: '',
                style:{
                    "color": '#4d4c4c',
                    fontSize: "14px"
                }
            },
            itemStyle: {
                "color": '#4d4c4c',
                fontSize: '14px',
                fontWeight: 400,
            },
            itemMarginTop: 4,
            itemMarginBottom: 4,
            layout: 'horizontal',
            align: 'left',
            verticalAlign: 'bottom',
            floating: false,
            backgroundColor: '#FFFFFF',
//        borderColor: '#CCC',
//        borderWidth: 1,
//        x: 120,
//        y: 60
            labelFormatter: function () {
                return {
                    'Single' : 'Single: 68',
                    'Hybrid' : 'Hybrid: 178',
                    'Multi' : 'Multi: 65',
                    'Other' : 'Other: 39',
                }[this.name];
            }
        };
        var plotOptions = {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                pointWidth: 35,
                dataLabels: {
                    enabled: false,
                    formatter: function () {
//                                var chart = $('#chart_08').highcharts(),
//                                    extremes = chart.yAxis[0].getExtremes();
//
//
//                                var pcnt = (this.y / extremes.dataMax) * 100;
//                                for (var i = 0; i < series.length; i++) {
//                                    series[i][1] = Highcharts.numberFormat(pcnt);
//                                }
                        return this.y + '%';
                    },
                    style: { fontSize: '14px' }
                }
            }
        };
        var series = [
            {
                name: 'Single',
                color: '#00bceb',
                data: [0, 13, 30],
            },
            {
                name: 'Hybrid',
                color: '#6abf4b',
                data: [0, 8, 19],
            },
            {
                name: 'Multi',
                color: '#1e4471',
                data: [0, 27, 48],
            },
            {
                name: 'Other',
                color: '#a7a9ac',
                data: [0, 9, 30],
            }
        ];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }

        var json = {};
        json.chart = chart;
        json.title = title;
        json.subtitle = subtitle;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.plotOptions = plotOptions;
        json.series = series;
        json.legend = legend;
        json.tooltip = tooltip;
        return json;
    }

    load_chart_nine_one() {
        var chart = {
            type: 'column',
            style: {
                fontFamily: 'CiscoSans'
            },
        };
        var title = {
            text: '',
            style: {
                color: '#1E4471',
                fontWeight: 'bold'
            }
        };
        var subtitle = {
            text: '',
            style: {
                "color": "#1E4471",
                fontWeight: 'bold',
            },
            verticalAlign: "top",
            floating: false,
            color: "#1E4471"
        };
        var xAxis = {
            categories: ['Finance/Healthcare', 'Retail'],
            labels: {
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
        };
        var yAxis = {
            title: {
                text: ''
            },
            labels: {
                format: '{value}%',
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
            max: 50,
            min: 0,
            tickInterval: 10
        };
        var tooltip = {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>'
        };
        var credits = {
            enabled: false
        };
        var plotOptions = {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                pointWidth: 25,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return (this.y) + '%';
                    }
                }
            }
        };
        var series = [
            {
                name: '2020 vs 2019 growth in cloud workloads',
                color: '#00bceb',
                data: [30, 45]
            }
        ];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }
        chart.series = series.map(function (project) {
            return {
                name: project.name,
                data: project.data.map(function (point, dataIndex) {
                    return {
                        y: (point / dataMaxes[dataIndex]) * 100,
                        absoluteY: point
                    };
                })
            }
        });

        var json = {};
        json.chart = chart;
        json.title = title;
        json.subtitle = subtitle;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.plotOptions = plotOptions;
        json.series = series;
        json.tooltip = tooltip;
        return json;
        /*$('#chart_09_1').highcharts(json, function(chart) {
            setTimeout(function() {
                chart.reflow();
            });
        });*/
    }

    load_chart_nine_two () {
        var chart = {
            type: 'pie',
            style: {
                fontFamily: 'CiscoSans'
            },
        };
        var title = {
            text: 'Actions on new workloads',
            style: {
                color: '#4d4c4c',
                fontSize: '14px'
            }
        };
        var subtitle = {
            text: '',
            style: {
                "color": "#4d4c4c",
                fontWeight: 'bold'
            },
            color: "#1E4471"
        };
        var xAxis = {
            categories: []
        };
        var yAxis = {
            title: {
                text: 'Percentage of Customers',
            },
            labels: {
                format: '{value}%'
            }
        };
        var tooltip = {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.absoluteY}</b><br/>'
        };
        var credits = {
            enabled: false
        };
        var plotOptions = {
            series: {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return Math.round(this.percentage * 100) / 100 + ' %';
                    },
                    distance: -40,
                }
            },
            pie: {
                dataLabels: {
                    enabled: true,
                    style: {
                        color: '#0d274d',
                        fontSize: '20px',
                        fontWeight: 400,
                        textOutline: false,
                    }
                }
            }
        };
        var series = [{
            name: '2020',
            type: 'pie',
            data: [
                {
                    name: 'Scale down',
                    y: 98,
                    color: '#00bceb'
                }, {
                    name: 'Other Actions',
                    y: 2,
                    color: '#6abf4b'
                }
            ],
            showInLegend: true,
            dataLabels: {
                enabled: true
            }
        }];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }

        var json = {};
        json.chart = chart;
        json.title = title;
        json.subtitle = subtitle;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.plotOptions = plotOptions;
        json.series = series;
        return json;
        // $('#chart_09_2').highcharts(json);
    }

    load_chart_nine_three () {
        var chart = {
            type: 'pie',
            style: {
                fontFamily: 'CiscoSans'
            },
        };
        var title = {
            text: 'Initial workload placement with<br/>CWOM/IWO',
            style: {
                color: '#4d4c4c',
                fontSize: '14px'
            }
        };
        var subtitle = {
            text: '',
            style: {
                "color": "#1E4471",
                fontWeight: 'bold'
            },
            color: "#1E4471"
        };
        var xAxis = {
            categories: ['Malware', 'Phishing', 'Cryptomining', 'Trojans']
        };
        var yAxis = {
            title: {
                text: 'Percentage of Customers'
            },
            labels: {
                format: '{value}%'
            }
        };
        var tooltip = {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.absoluteY}</b><br/>'
        };
        var credits = {
            enabled: false
        };
        var plotOptions = {
            series: {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return Math.round(this.percentage * 100) / 100 + ' %';
                    },
                    distance: -40,
                }
            },
            pie: {
                dataLabels: {
                    enabled: true,
                    style: {
                        color: '#0d274d',
                        fontSize: '20px',
                        fontWeight: 400,
                        textOutline: false
                    }
                }
            }
        };
        var series = [{
            name: '2020',
            type: 'pie',
            data: [
                {
                    name: 'Accuracy',
                    y: 92,
                    color: '#00bceb'
                }, {
                    name: 'Other',
                    y: 8,
                    color: '#6abf4b'
                }
            ],
            showInLegend: true,
            dataLabels: {
                enabled: true
            }
        }];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }

        var json = {};
        json.chart = chart;
        json.title = title;
        json.subtitle = subtitle;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.plotOptions = plotOptions;
        json.series = series;
        return json;
        // $('#chart_09_3').highcharts(json);
    }

    load_chart_nine() {
        var chart = {
            type: 'column',
            style: {
                fontFamily: 'CiscoSans'
            }
        };
        var title = {
            text: '',
            style: {
                color: '#1E4471',
                fontWeight: 'bold'
            }
        };
        var subtitle = {
            text: '',
            style: {
                "color": "#1E4471",
                fontWeight: 'bold',
            },
            verticalAlign: "top",
            floating: false,
            color: "#1E4471"
        };
        var xAxis = {
            categories: ['Finance/Healthcare', 'Retail'],
            labels: {
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
        };
        var yAxis = {
            title: {
                text: ''
            },
            labels: {
                format: '{value}%',
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
            max: 50,
            min: 0,
            tickInterval: 10
        };
        var tooltip = {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:.2f}</b><br/>'
        };
        var credits = {
            enabled: false
        };
        var plotOptions = {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                pointWidth: 25,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return (this.y) + '%';
                    }
                }
            }
        };
        var series = [
            {
                name: '2020 vs 2019 growth in cloud workloads',
                color: '#00bceb',
                data: [30, 45]
            }
        ];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }
        chart.series = series.map(function (project) {
            return {
                name: project.name,
                data: project.data.map(function (point, dataIndex) {
                    return {
                        y: (point / dataMaxes[dataIndex]) * 100,
                        absoluteY: point
                    };
                })
            }
        });

        var json = {};
        json.chart = chart;
        json.title = title;
        json.subtitle = subtitle;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.plotOptions = plotOptions;
        json.series = series;
        json.tooltip = tooltip;
        $('#chart_09_1').highcharts(json, function(chart) {
            setTimeout(function() {
                chart.reflow();
            });
        });

        var chart = {
            type: 'pie',
            style: {
                fontFamily: 'CiscoSans'
            }
        };
        var title = {
            text: 'Actions on new workloads',
            style: {
                color: '#4d4c4c',
                fontSize: '14px'
            }
        };
        var subtitle = {
            text: '',
            style: {
                "color": "#4d4c4c",
                fontWeight: 'bold'
            },
            color: "#1E4471"
        };
        var xAxis = {
            categories: []
        };
        var yAxis = {
            title: {
                text: 'Percentage of Customers',
            },
            labels: {
                format: '{value}%'
            }
        };
        var tooltip = {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.absoluteY}</b><br/>'
        };
        var credits = {
            enabled: false
        };
        var plotOptions = {
            series: {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return Math.round(this.percentage * 100) / 100 + ' %';
                    },
                    distance: -40,
                }
            },
            pie: {
                dataLabels: {
                    enabled: true,
                    style: {
                        color: '#0d274d',
                        fontSize: '20px',
                        fontWeight: 400,
                        textOutline: false,
                    }
                }
            }
        };
        var series = [{
            name: '2020',
            type: 'pie',
            data: [
                {
                    name: 'Scale down',
                    y: 98,
                    color: '#00bceb'
                }, {
                    name: 'Other Actions',
                    y: 2,
                    color: '#6abf4b'
                }
            ],
            showInLegend: true,
            dataLabels: {
                enabled: true
            }
        }];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }

        var json = {};
        json.chart = chart;
        json.title = title;
        json.subtitle = subtitle;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.plotOptions = plotOptions;
        json.series = series;
        $('#chart_09_2').highcharts(json);
        var chart = {
            type: 'pie',
            style: {
                fontFamily: 'CiscoSans'
            }
        };
        var title = {
            text: 'Initial workload placement with<br/>CWOM/IWO',
            style: {
                color: '#4d4c4c',
                fontSize: '14px'
            }
        };
        var subtitle = {
            text: '',
            style: {
                "color": "#1E4471",
                fontWeight: 'bold'
            },
            color: "#1E4471"
        };
        var xAxis = {
            categories: ['Malware', 'Phishing', 'Cryptomining', 'Trojans']
        };
        var yAxis = {
            title: {
                text: 'Percentage of Customers'
            },
            labels: {
                format: '{value}%'
            }
        };
        var tooltip = {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.absoluteY}</b><br/>'
        };
        var credits = {
            enabled: false
        };
        var plotOptions = {
            series: {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return Math.round(this.percentage * 100) / 100 + ' %';
                    },
                    distance: -40,
                }
            },
            pie: {
                dataLabels: {
                    enabled: true,
                    style: {
                        color: '#0d274d',
                        fontSize: '20px',
                        fontWeight: 400,
                        textOutline: false
                    }
                }
            }
        };
        var series = [{
            name: '2020',
            type: 'pie',
            data: [
                {
                    name: 'Accuracy',
                    y: 92,
                    color: '#00bceb'
                }, {
                    name: 'Other',
                    y: 8,
                    color: '#6abf4b'
                }
            ],
            showInLegend: true,
            dataLabels: {
                enabled: true
            }
        }];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }

        var json = {};
        json.chart = chart;
        json.title = title;
        json.subtitle = subtitle;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.plotOptions = plotOptions;
        json.series = series;
        $('#chart_09_3').highcharts(json);
    }

    load_chart_ten() {
        var chart = {
            type: 'column',
            style: {
                fontFamily: 'CiscoSans'
            },
        };
        var title = {
            text: '',
            style: {
                color: '#4d4c4c',
                fontWeight: 'bold',
            }
        };
        var subtitle = {
            text: '',
            style: {
                "color": "#4d4c4c",
                fontWeight: 'bold',
            },
            verticalAlign: "top",
            floating: false,
            color: "#4d4c4c"
        };
        var xAxis = {
            categories: ['Malware', 'Phishing', 'Cryptomining', 'Trojans'],
            labels: {
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            }
        };
        var yAxis = {
            title: {
                text: 'Percentage of Customers',
                style: {
                    color: '#4d4c4c',
                    fontSize: '16px',
                }
            },
            labels: {
                format: '{value}%',
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
            max: 100,
            min: 0,
            tickInterval: 20
        };
        var tooltip = {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>'
        };
        var credits = {
            enabled: false
        };
        var plotOptions = {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                pointWidth: 25,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return (this.y) + '%';
                    },
                    style: { fontSize: '14px' }
                }
            }
        };
        var series = [
            {
                name: '2019',
                color: '#00bceb',
                data: [88, 46, 56, 59]
            },
            {
                name: '2020',
                color: '#6abf4b',
                data: [91, 85, 68, 63]
            }
        ];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }

        var json = {};
        json.chart = chart;
        json.title = title;
        json.subtitle = subtitle;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.plotOptions = plotOptions;
        json.series = series;
        json.tooltip = tooltip;
        return json;
        // $('#chart_10').highcharts(json);
    }



    load_chart_eleven_two() {
        var chart = {
            type: 'pie',

            style: {
                fontFamily: 'CiscoSans'
            },
            height: 440,
        };
        var title = {
            text: '2020',
            style: {
                color: '#1e4471',
                fontSize: '20px',
            }
        };

        var xAxis = {
            categories: ['Malware', 'Phishing', 'Cryptomining', 'Trojans']
        };
        var yAxis = {
            title: {
                text: '% of Customers'
            },
            labels: {
                format: '{value}%'
            },
            max: 100,
            min: 0,
            tickInterval: 20
        };
        var tooltip = {
            valueSuffix: '%'
        };
        var credits = {
            enabled: false
        };
        var legend = {
            itemDistance: 30,
            itemMarginTop: 3,
            itemMarginBottom: 3,
            itemStyle: {
                "color": '#4d4c4c',
            },
            labelFormatter: function () {
                return {
                    'Managed Service Providers' : 'Managed Service Providers: 25.98%',
                    'Financial Services' : 'Financial Services: 21.94%',
                    'Manufacturing' : 'Manufacturing: 8.4%',
                    'Healthcare' : 'Healthcare: 7.12%',
                    'Technology: Soft/Hardware' : 'Technology: Soft/Hardware: 7.11%',
                    'Higher Education' : 'Higher Education: 4.36%',
                    'Government' : 'Government: 4.17%',
                    'Professional Services' : 'Professional Services: 3.55%',
                    'Retail' : 'Retail: 2.39%',
                    'Other' : 'Other: 14.98%',
                }[this.name];
            }
        };
        var plotOptions = {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                pointWidth: 25,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return (this.y) + '%';
                    }
                }
            }
        };
        var getColor = {
            'Managed Service Providers': '#00bceb',
            'Financial Services': '#e2231a',
            'Manufacturing': '#fbab18',
            'Healthcare': '#b4dea2',
            'Technology: Soft/Hardware': '#1e4471',
            'Higher Education': '#6abf4b',
            'Government': '#80ddf4',
            'Professional Services': '#a7a9ac',
            'Retail': '#f0908c',
            'Other': '#8ea1b8'
        };
        var series = [{
            name: '2020',
            type: 'pie',
            data: [
                {
                    name: 'Managed Service Providers',
                    y: 25.98,
                    color: getColor['Managed Service Providers']
                }, {
                    name: 'Financial Services',
                    y: 21.94,
                    color: getColor['Financial Services']
                }, {
                    name: 'Manufacturing',
                    y: 8.40,
                    color: getColor['Manufacturing']
                }, {
                    name: 'Healthcare',
                    y: 7.12,
                    color: getColor['Healthcare']
                }, {
                    name: 'Technology: Soft/Hardware',
                    y: 7.11,
                    color: getColor['Technology: Soft/Hardware']
                },
                {
                    name: 'Higher Education',
                    y: 4.36,
                    color: getColor['Higher Education']
                }, {
                    name: 'Government',
                    y: 4.17,
                    color: getColor['Government']
                }, {
                    name: 'Professional Services',
                    y: 3.55,
                    color: getColor['Professional Services']
                }, {
                    name: 'Retail',
                    y: 2.39,
                    color: getColor['Retail']
                }, {
                    name: 'Other',
                    y: 14.98,
                    color: getColor['Other']
                }
            ],
            size: '100%',
            innerSize: '60%',
            showInLegend: true,
            dataLabels: {
                enabled: false
            }
        }];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }

        var json = {};
        json.chart = chart;
        json.title = title;

        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.plotOptions = plotOptions;
        json.series = series;
        json.legend = legend;
        json.tooltip = tooltip;

        return json;
        // $('#chart_11_2').highcharts(json);
    }

    load_chart_eleven_one() {
        var chart = {
            type: 'pie',
            style: {
                fontFamily: 'CiscoSans'
            },
            height: 440,
        };
        var title = {
            text: '2019',
            style: {
                color: '#1e4471',
                fontSize: '20px',
            },
        };

        var xAxis = {
            categories: ['Malware', 'Phishing', 'Cryptomining', 'Trojans']
        };
        var yAxis = {
            title: {
                text: '% of Customers'
            },
            labels: {
                format: '{value}%'
            },
            max: 100,
            min: 0,
            tickInterval: 20
        };
        var tooltip = {
            valueSuffix: '%'
        };
        var credits = {
            enabled: false
        };
        var legend = {
            itemDistance: 30,
            itemMarginTop: 3,
            itemMarginBottom: 3,
            itemStyle: {
                "color": '#4d4c4c',
            },
            labelFormatter: function () {
                return {
                    'Higher Education' : 'Higher Education: 21.38%',
                    'Financial Services' : 'Financial Services: 21.37%',
                    'Managed Service Providers' : 'Managed Service Providers: 18.63%',
                    'Manufacturing' : 'Manufacturing: 8.36%',
                    'Professional Services' : 'Professional Services: 3.42%',
                    'Technology: Soft/Hardware' : 'Technology: Soft/Hardware: 3.15%',
                    'Government' : 'Government: 2.81%',
                    'Energy/Utilities' : 'Energy/Utilities: 2.8%',
                    'Retail' : 'Retail: 2.27%',
                    'Other' : 'Other: 15.81%',
                }[this.name];
            }
        };
        var plotOptions = {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                pointWidth: 25,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return (this.y) + '%';
                    }
                }
            }
        };
        var getColor = {
            'Higher Education': '#6abf4b',
            'Financial Services': '#e2231a',
            'Managed Service Providers': '#00bceb',
            'Manufacturing': '#fbab18',
            'Professional Services': '#a7a9ac',
            'Technology: Soft/Hardware': '#1e4471',
            'Government': '#80ddf4',
            'Energy/Utilities': '#b4dea2',
            'Retail': '#f0908c',
            'Other': '#8ea1b8'
        };
        var series = [{
            name: '2019',
            type: 'pie',
            data: [
                {
                    name: 'Higher Education',
                    y: 21.38,
                    color: getColor['Higher Education']
                }, {
                    name: 'Financial Services',
                    y: 21.37,
                    color: getColor['Financial Services']
                }, {
                    name: 'Managed Service Providers',
                    y: 18.63,
                    color: getColor['Managed Service Providers']
                }, {
                    name: 'Manufacturing',
                    y: 8.36,
                    color: getColor['Manufacturing']
                }, {
                    name: 'Professional Services',
                    y: 3.42,
                    color: getColor['Professional Services']
                }, {
                    name: 'Technology: Soft/Hardware',
                    y: 3.15,
                    color: getColor['Technology: Soft/Hardware']
                }, {
                    name: 'Government',
                    y: 2.81,
                    color: getColor['Government']
                }, {
                    name: 'Energy/Utilities',
                    y: 2.8,
                    color: getColor['Energy/Utilities']
                }, {
                    name: 'Retail',
                    y: 2.27,
                    color: getColor['Retail']
                }, {
                    name: 'Other',
                    y: 15.81,
                    color: getColor['Other']
                }
            ],
            size: '100%',
            innerSize: '60%',
            showInLegend: true,
            dataLabels: {
                enabled: false
            }
        }];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }

        var json = {};
        json.chart = chart;
        json.title = title;

        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.plotOptions = plotOptions;
        json.series = series;
        json.legend = legend;
        json.tooltip = tooltip;
        return json;
        // $('#chart_11_1').highcharts(json);
    }

    load_chart_twelve_one() {
        var chart = {
            type: 'line',
            style: {
                fontFamily: 'CiscoSans'
            },
        };
        var title = {
            text: '',
            style: {
                color: '#4d4c4c',
                fontSize: '18px',
                textAlign: 'center'
            },
            align: 'center',
            useHTML: true
        };
        var subtitle = {
            text: '',
            style: {
                "color": "#4d4c4c",
                fontWeight: 'bold',
            },
            verticalAlign: "top",
            floating: false,
            color: "#1E4471"
        };
        var xAxis = {
            categories: ['Before <br>COVID-19', 'During <br>COVID-19', 'After <br>COVID-19'],
            labels: {
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            }
        };
        var yAxis = {
            title: {
                text: '% of organizations with more than half remote workforce',
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
            labels: {
                format: '{value}%',
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
            max: 80,
            min: 0,
            tickInterval: 10
        };
        var tooltip = {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>'
        };
        var credits = {
            enabled: false
        };
        var plotOptions = {
            line: {
                pointPadding: 0.2,
                borderWidth: 0,
                pointWidth: 25,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return (this.y) + '%';
                    },
                    style: {
                        color: '#0d274d',
                        fontSize: '16px',
                        fontWeight: '400'
                    }
                }
            }
        };
        var series = [
            {
                showInLegend: false,
                name: '',
                color: '#00bceb',
                data: [19, 62, 37]
            }
        ];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }

        var json = {};
        json.chart = chart;
        json.title = title;
        json.subtitle = subtitle;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.plotOptions = plotOptions;
        json.series = series;
        json.tooltip = tooltip;
        // $('#chart_12_1').highcharts(json);
        return json;
    }

    load_chart_twelve_two() {
        var chart = {
            type: 'column',
            style: {
                fontFamily: 'CiscoSans'
            },

        };
        var title = {
            text: '',
            style: {
                color: '#4d4c4c',
                fontSize: '18px',
                paddingLeft: '5px'
            }
        };
        var subtitle = {
            text: '',
            style: {
                "color": "#4d4c4c",
                fontWeight: 'bold',
            },
            verticalAlign: "top",
            floating: false,
            color: "#1E4471"
        };
        var xAxis = {
            categories: ['Before <br>COVID-19', 'During <br>COVID-19', 'After <br>COVID-19'],
            labels: {
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            }
        };
        var yAxis = {
            title: {
                text: '% of organizations with more than half remote workforce',
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
            labels: {
                format: '{value}%',
                style:{
                    color: "#4d4c4c",
                    fontSize: "14px"
                }
            },
            max: 80,
            min: 0,
            tickInterval: 10
        };
        var tooltip = {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>'
        };
        var credits = {
            enabled: false
        };
        var plotOptions = {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                pointWidth: 25,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return (this.y) + '%';
                    },
                    style: {
                        color: '#0d274d',
                        fontSize: '13px',
                        fontWeight: '400'
                    }
                }
            }
        };
        var series = [
            {
                name: 'Small',
                color: '#00bceb',
                data: [19, 60, 35]
            },
            {
                name: 'Medium',
                color: '#1e4471',
                data: [22, 57, 38]
            },
            {
                name: 'Large',
                color: '#6abf4b',
                data: [18, 68, 37]
            }
        ];

        var listLen = series.length,
            dataLen = series[0].data.length,
            dataMaxes = series[0].data.slice(),
            point,
            dataIndex,
            listIndex;

        for (dataIndex = 0; dataIndex < dataLen; dataIndex++) {
            for (listIndex = 1; listIndex < listLen; listIndex++) {
                point = series[listIndex].data[dataIndex]
                if (point > dataMaxes[dataIndex]) {
                    dataMaxes[dataIndex] = point;
                }
            }
        }

        var json = {};
        json.chart = chart;
        json.title = title;
        json.subtitle = subtitle;
        json.xAxis = xAxis;
        json.yAxis = yAxis;
        json.credits = credits;
        json.plotOptions = plotOptions;
        json.series = series;
        json.tooltip = tooltip;
        // $('#chart_12_2').highcharts(json);
        return json;
    }
}

export default AnimatedCharts
